import { BaseEntity, BaseEntityType, TRANSFORMERS_KEY } from './base';
import moment, { MomentInput } from 'moment';

import { addMetadataToMap } from './util';

/**
 * BaseEntity property decorator to provide custom transformation of values
 * @param  {(value:any)=>any} transformer transform function
 */
export const Transform = (transformer: (value: any) => any) => {
  return <T extends BaseEntity, K extends string>(target: T, key: K) => {
    addMetadataToMap(TRANSFORMERS_KEY, key, transformer, target);
  };
};

// Tranformers

/**
 * Convert json date to native Date
 * @param  {} value any valid MomentInput
 * @returns Date
 */
export const toDate = (value: MomentInput): Date => moment(value).toDate();

/**
 * Convert string to number
 * @param  value any strigified number
 * @returns number
 */
export const toNumber = (value: string): number => parseInt(value);

/**
 * Convert value to string
 * @param  value any
 * @returns string
 */
export const toString = (value: any): string => String(value);

/**
 * Convert empty string to null
 * @param value string
 * @returns string | null
 */
export const emptyStringToNull = (value: string): string | null => value || null;

/**
 * Tranformer factory to convert value to specified BaseEntity
 * @param  {BaseEntityType<T>} entity
 * @returns (value) => BaseEntity | BaseEntity[]
 */
export function toEntity<T extends BaseEntity>(entity: BaseEntityType<T>) {
  return (value): T | T[] => {
    if (Array.isArray(value)) {
      return value.map((item) => new entity(item));
    } else {
      if (typeof value !== 'object') {
        throw 'Entity transformation value must be an object';
      }
      return new entity(value);
    }
  };
}
