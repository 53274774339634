import { ApiService, EmptySubscriber } from '../api.service';
import { AppState, getAll, getCollection } from 'src/state/state';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Make, Model, Vehicle, VehicleParams } from 'src/app/models/vehicle';
import { finalize, map, tap } from 'rxjs/operators';

import { CollectionActions } from 'src/state/actions';
import { EventsService } from '../../events/events.service';
import { FuelType } from 'src/app/models/fuel-type';
import { Injectable } from '@angular/core';
import { Mileage } from 'src/app/models/mileage';
import { SessionService } from '../../session/session.service';
import { Store } from '@ngrx/store';
import { TirePressure } from 'src/app/models/tire-pressure';
import { TireTread } from 'src/app/models/tire-tread';
import { UserEvent } from '../user/user.service';
import { VehicleRecall } from 'src/app/models/vehicle-recall';
import vinValidator from 'vin-validator';

@Injectable({
  providedIn: 'root',
})
export class VehiclesService {
  private static SELECTED_VEHICLE_KEY = 'selected_vehicle';
  static readonly MAX_VEHICLES_LIMIT = 3;

  placeholderVehicles$: Observable<Nullable<Vehicle[]>>;
  vehicles$: Observable<Vehicle[]>;
  isLoading$: Observable<boolean>;
  isLoaded$: Observable<boolean>;
  selectedVehicle$: BehaviorSubject<Nullable<Vehicle>> = new BehaviorSubject(null);
  selectedVehicleMileages$: BehaviorSubject<Mileage[]> = new BehaviorSubject([]);
  private _selectedVehicle: Nullable<Vehicle>;

  emptySubscriber = new EmptySubscriber();
  constructor(
    private api: ApiService,
    private store: Store<AppState>,
    private collectionActions: CollectionActions,
    private events: EventsService,
    private session: SessionService
  ) {
    this.vehicles$ = store.select((state) => getAll(state, Vehicle));
    this.placeholderVehicles$ = this.vehicles$.pipe(
      map((data) => data.filter((vehicle) => vehicle.vehicleModel.placeholder))
    );
    this.isLoading$ = store.select((state) => getCollection(state, Vehicle).loading);
    this.isLoaded$ = store.select((state) => getCollection(state, Vehicle).loaded);
    this.events.subscribe(UserEvent.UserDidLogout, () => this.logoutHandler());
  }

  logoutHandler() {
    this.setSelectedVehicle(null);
    this.selectedVehicleMileages$.next([]);
  }

  setSelectedVehicle(vehicle: Nullable<Vehicle>) {
    const payload = vehicle ? vehicle.json : null;
    const promise = this.session.set(VehiclesService.SELECTED_VEHICLE_KEY, payload);
    this._selectedVehicle = vehicle;
    this.selectedVehicle$.next(this._selectedVehicle);
    this.selectedVehicleMileages$.next([]);

    return promise;
  }

  loadSelectedVehicle() {
    this.session.get(VehiclesService.SELECTED_VEHICLE_KEY).then((data) => {
      if (data) {
        this._selectedVehicle = new Vehicle(data);
        this.selectedVehicle$.next(this._selectedVehicle);
        this.refreshSelectedVehicle(this._selectedVehicle);
      } else {
        this.getVehicles().subscribe(
          (vehicles: Vehicle[]) => {
            if (vehicles.length) {
              const selectedVehicle = vehicles[0];
              this.setSelectedVehicle(selectedVehicle);
            } else {
              this.setSelectedVehicle(null);
              console.log('Unable to get vehicles');
            }
          },
          (error) => {
            console.log('Error getting vehicles', error);
          }
        );
      }
    });
  }

  getVehicles(): Observable<Vehicle[]> {
    this.store.dispatch(this.collectionActions.setLoading(Vehicle, true));
    const request = this.api
      .call({
        method: 'GET',
        url: `/users/${ApiService.USER_UID}/vehicles`,
      })
      .pipe(
        map((result) => result.map((item) => new Vehicle(item))),
        finalize(() => this.store.dispatch(this.collectionActions.setLoading(Vehicle, false))),
        tap((vehicles) => {
          if (!this._selectedVehicle && vehicles.length) {
            this.setSelectedVehicle(vehicles[0]);
          }

          this.store.dispatch(this.collectionActions.set(Vehicle, vehicles));
        })
      );
    request.subscribe(this.emptySubscriber);
    return request;
  }

  createVehicle(params: VehicleParams) {
    const request = this.api
      .call({
        url: `/users/${ApiService.USER_UID}/vehicles`,
        method: 'POST',
        body: params,
      })
      .pipe(
        map((item) => new Vehicle(item)),
        tap((vehicle) => {
          this.store.dispatch(this.collectionActions.add(Vehicle, vehicle));
        })
      );
    request.subscribe(this.emptySubscriber);
    return request;
  }

  updateVehicle(vehicle: Vehicle, params: VehicleParams) {
    const request = this.api
      .call({
        method: 'PATCH',
        url: '/vehicles/' + vehicle.uid,
        body: params,
      })
      .pipe(
        map((item) => new Vehicle(item)),
        tap((vehicle) => {
          this.store.dispatch(this.collectionActions.update(Vehicle, vehicle));

          if (this._selectedVehicle && this._selectedVehicle.uid === vehicle.uid) {
            this.setSelectedVehicle(vehicle);
          }
        })
      );
    request.subscribe(this.emptySubscriber);
    return request;
  }

  deleteVehicle(vehicle: Vehicle) {
    const request = this.api.call({
      method: 'DELETE',
      url: '/vehicles/' + vehicle.uid,
    });
    request
      .pipe(
        tap((data) => {
          this.store.dispatch(this.collectionActions.delete(Vehicle, vehicle));
          if (vehicle.uid === this._selectedVehicle?.uid) {
            this.setSelectedVehicle(null).then(() => {
              this.loadSelectedVehicle();
            });
          }
        })
      )
      .subscribe(this.emptySubscriber);
    return request;
  }

  updateVehicleImage(vehicle: Vehicle, img: string) {
    const request = this.api.call({
      method: 'POST',
      url: `/vehicles/${vehicle.uid}/photos`,
      body: { image: img, taken_at: new Date().toUTCString() },
    });
    request.subscribe(new EmptySubscriber());
    return request;
  }

  validateVin(vin: string): boolean {
    return vinValidator.validate(vin);
  }

  // Tire

  getTirePressuresForVehicle(vehicle: Vehicle) {
    return this.api
      .call({
        url: `/vehicles/${vehicle.uid}/tire_pressures`,
        method: 'GET',
      })
      .pipe(map((result) => result.map((item) => new TirePressure(item))));
  }

  getTireTreadsForVehicle(vehicle: Vehicle) {
    return this.api
      .call({
        url: `/vehicles/${vehicle.uid}/tire_treads`,
        method: 'GET',
      })
      .pipe(map((result) => result.map((item) => new TireTread(item))));
  }

  // Vehicle Makes

  getCarMakes() {
    return this.api.call({ url: '/vehicle_makes' }).pipe(
      map((result) => result.map((item) => new Make(item))),
      map((makes) => this.sortCarMakes(makes))
    );
  }

  getCarModels(makeUID: string) {
    return this.api
      .call({
        url: '/vehicle_makes/' + makeUID,
      })
      .pipe(
        map((result) => result.vehicle_models.map((item) => new Model(item))),
        map((models) => this.sortCarModels(models))
      );
  }

  // Fuel Type

  getFuelsTypes() {
    return this.api
      .call({
        url: '/fuel_types',
      })
      .pipe(map((result) => result.map((item) => new FuelType(item))));
  }

  // Recalls

  getVehicleRecalls(uid: string): Observable<VehicleRecall[]> {
    const request: Observable<VehicleRecall[]> = this.api
      .call({ url: `/vehicles/${uid}/recalls`, method: 'GET' })
      .pipe(
        map((recalls) => {
          return recalls.map((recall) => new VehicleRecall(recall));
        })
      );

    request.subscribe(new EmptySubscriber());
    return request;
  }

  // Mileage
  getVehicleMileage(page = 1): Observable<Mileage[]> {
    if (this.selectedVehicle$.value) {
      const request: Observable<Mileage[]> = this.api
        .call({
          method: 'GET',
          url: `/vehicles/${this.selectedVehicle$.value.uid}/mileages`,
          includePageInfo: true,
          params: { page },
        })
        .pipe(
          map((res) => {
            const response = res.response || [];
            return response.map((mileageItem) => new Mileage(mileageItem));
          }),
          tap((mileages: Mileage[]) => {
            const prevMileages = page === 1 ? [] : [...this.selectedVehicleMileages$.value];
            this.selectedVehicleMileages$.next([...prevMileages, ...mileages]);
          })
        );
      return request;
    }
    return of([]);
  }

  addMiles(uid: string, mileage: number) {
    const request = this.api.call({
      method: 'POST',
      url: `/vehicles/${uid}/mileages`,
      body: { mileage },
    });
    return request;
  }

  deleteMiles(vehicleUid, mileageUid) {
    const request = this.api.call({
      method: 'DELETE',
      url: `/vehicles/${vehicleUid}/mileages/${mileageUid}`,
    });
    return request;
  }

  // private methods
  private refreshSelectedVehicle(vehicle: Vehicle) {
    if (vehicle.uid) {
      this.getVehicleByUid(vehicle.uid).subscribe(
        (vehicle: Vehicle) => {
          this.setSelectedVehicle(vehicle);
        },
        () => {
          this.setSelectedVehicle(null);
        }
      );
    }
  }

  private getVehicleByUid(vehicleUid: string) {
    const request = this.api
      .call({
        url: `/vehicles/${vehicleUid}`,
        method: 'GET',
      })
      .pipe(
        finalize(() => {}),
        map((item) => new Vehicle(item)),
        tap((vehicle) => {
          this.store.dispatch(this.collectionActions.update(Vehicle, vehicle));
        })
      );
    request.subscribe(new EmptySubscriber());
    return request;
  }

  private sortCarMakes(makes) {
    return makes.sort((a, b) => {
      if (a.make < b.make) {
        return -1;
      }
      if (a.make > b.make) {
        return 1;
      }
      return 0;
    });
  }

  private sortCarModels(models) {
    return models.sort((a, b) => {
      if (a.model < b.model) {
        return -1;
      }
      if (a.model > b.model) {
        return 1;
      }
      return 0;
    });
  }
}
