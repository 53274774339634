import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FeatureFlagService, Flag } from 'src/app/services/feature-flag/feature-flag.service';
import { Flow, FlowDirector, FlowPage, FlowPageOptions } from '../../flow-director';
import { MultipleOrderParams, OrderParams } from 'src/app/models/order';
import { OrderFlowEvents, OrderService } from 'src/app/services/api/order/order.service';
import { PinFlowPage, PinFlowPageForm } from '../pin-flow/pin-flow.page';
import {
  RequestedWindow,
  TimeWindowSelectorPage,
} from '../selectors/time-window-selector/time-window-selector.page';
import { Shift, ShiftName } from 'src/app/models/shift';
import { Subject, combineLatest } from 'rxjs';
import { UberEventsService, YoshiAppEvent } from '../../webflow/services/uber-events.service';
import { Vehicle, VehicleParams } from 'src/app/models/vehicle';
import {
  VehicleSubscription,
  VehicleSubscriptionParams,
} from 'src/app/models/vehicle-subscription';
import { finalize, take, takeUntil } from 'rxjs/operators';

import { AddressService } from 'src/app/services/api/address/address.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { AppointmentBuilderPage } from '../appointment-builder/appointment-builder.page';
import { BackButtonService } from 'src/app/services/back-button/back-button.service';
import { CapacityDisclaimerPage } from './capacity-disclaimer/capacity-disclaimer.page';
import { CorporatePromoPage } from './corporate-promo/corporate-promo.page';
import { DatePipe } from '@angular/common';
import { Day } from 'src/app/models/day';
import { DaySelectorPage } from '../selectors/day-selector/day-selector.page';
import { DayTimeSelectorPage } from '../selectors/day-time-selector/day-time-selector.page';
import { EventsService } from 'src/app/services/events/events.service';
import { FuelSelectorPage } from '../selectors/fuel-selector/fuel-selector.page';
import { IonNav } from '@ionic/angular';
import { LegalModalComponent } from './../../../../components/legal-modal/legal-modal';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { LoyaltyProgram } from 'src/app/models/loyalty/program';
import { LoyaltyService } from 'src/app/services/api/loyalty/loyalty.service';
import { MemberSubscription } from 'src/app/models/member-subscription';
import { MembershipService } from 'src/app/services/api/membership/membership.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { MultiVehiclePage } from 'src/app/pages/flows/multi-vehicle/multi-vehicle.page';
import { PlaceholderVehiclePage } from '../../placeholder-vehicle/placeholder-vehicle.page';
import { RequestReceivedModalPage } from '../request-received-modal/request-received-modal.page';
import { SelectMembershipPage } from 'src/app/pages/profile/membership/select-membership/select-membership.page';
import { Service } from 'src/app/models/service';
import { ServiceDetailPage } from 'src/app/pages/services/service-detail/service-detail.page';
import { ServiceGroup } from 'src/app/models/service-group';
import { ServiceName } from 'src/app/models/service-type';
import { TimeSlot } from 'src/app/models/time-slot';
import { TimeSlotSelectorPage } from '../selectors/time-slot-selector/time-slot-selector.page';
import { UserAddress } from 'src/app/models/user-address';
import { UserService } from 'src/app/services/api/user/user.service';
import { VehicleFlowController } from 'src/app/pages/flows/vehicle/vehicle-flow-controller/vehicle-flow-controller.page';
import { VehicleLicensePlatePage } from '../../vehicle/vehicle-license-plate/vehicle-license-plate.page';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';
import { WebflowVariant } from '../../webflow/webflow-start/webflow-start.variants';
import { YshPaymentSheetComponent } from 'src/app/components/ysh-payment-sheet/ysh-payment-sheet.component';
import moment from 'moment';

export interface OrderFlowControllerPageProps {
  userAddress: UserAddress;
  vehicle?: Nullable<Vehicle>;
  service?: Nullable<Service>;
  serviceGroup: ServiceGroup;
  skipPowerUps?: boolean;
  skipCompletionModal?: boolean;
  skipCompletionToast?: boolean;
  showServiceDetail?: boolean;
  outlineInputStyle?: boolean;
  webflowVariant?: WebflowVariant;
}

interface OrderFlowControllerForm {
  userAddress: UserAddress;
  vehicle?: Nullable<Vehicle>;
  service?: Nullable<Service>;
  days: Day[];
  date?: Date;
  window?: Nullable<Shift>;
  startTime?: number;
  endTime?: number;
  weekly?: boolean;
  frequency?: number;
  coords?: google.maps.LatLng;
  note?: string;
  availableSlots?: TimeSlot[];
  persistDeliveryLocation: boolean;
  slotStartTime?: Nullable<string>;
  duration?: string;
  multipleOrderParams?: Nullable<MultipleOrderParams[]>;
}

@Component({
  selector: 'ysh-order-flow-controller',
  templateUrl: './order-flow-controller.page.html',
  styleUrls: ['./order-flow-controller.page.scss'],
})
export class OrderFlowControllerPage implements OnDestroy, OnInit, AfterViewInit, FlowPage, Flow {
  @ViewChild('orderFlowNav', { static: true }) nav: IonNav;

  @Input() props: OrderFlowControllerPageProps;
  form: OrderFlowControllerForm;

  // FlowPage
  @Input() onComplete: (mapInfo?, userAddress?, note?) => void;
  @Input() onDismiss: () => void;
  @Input() preventBackNavigation = false;

  // Flow
  preventAnimation?: boolean = true;
  flowDirector: FlowDirector;

  loyaltyProgram: Nullable<LoyaltyProgram>;
  membership: Nullable<MemberSubscription>;
  needsCreditCard = false;
  didFinishPinPage = false;
  isCorporateAddress = false;
  showMemberSelect = false;
  hasFreeTrial = false;
  addedLicense = false;
  forGasOrder = false;
  serviceDetailShown = false;
  hasPlaceholderVehicles = false;

  protected animate = true;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private addressService: AddressService,
    private analytics: AnalyticsService,
    private datePipe: DatePipe,
    private featureFlags: FeatureFlagService,
    private loadingAlertCtrl: LoadingAlertService,
    private loyaltyService: LoyaltyService,
    private membershipService: MembershipService,
    private modalService: ModalService,
    private orderService: OrderService,
    private userService: UserService,
    private vehicleService: VehiclesService,
    private eventsService: EventsService,
    private uberService: UberEventsService,
    public backButtonService: BackButtonService
  ) {}

  ngOnInit(): void {
    this.analytics.trackView('Order Flow Controller');
    this.form = this.initForm(this.props);
    this.forGasOrder = this.props.serviceGroup.serviceType.name === ServiceName.Gas;
    this.initSubscriptions();
    this.promptForFuelTypeIfNeeded(this.form.vehicle);
    setTimeout(() => (this.animate = false), 300);
    setTimeout(() => this.presentCorporatePromoPageIfNeeded(), 500);
  }

  initForm(props: OrderFlowControllerPageProps): OrderFlowControllerForm {
    const serviceCount = props.serviceGroup.services.length;
    const skipServiceScreen = serviceCount === 1 && !props.showServiceDetail;
    const service = skipServiceScreen ? props.service : null;
    return {
      userAddress: props.userAddress,
      vehicle: props.vehicle,
      service,
      days: [],
      persistDeliveryLocation: false,
      duration: service?.durationString,
    };
  }

  ngAfterViewInit(): void {
    const options = this.nextPage();
    this.flowDirector = new FlowDirector(this, options);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  initSubscriptions() {
    this.userService.creditCards$.pipe(take(1)).subscribe((creditCards) => {
      this.needsCreditCard = !creditCards.length;
    });
    this.loyaltyService.currentProgram$.pipe(take(1)).subscribe((program) => {
      this.loyaltyProgram = program;
    });
    combineLatest([this.membershipService.membership$, this.membershipService.freeTrialInEffect$])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(([membership, freeTrial]) => {
        this.membership = membership;
        this.hasFreeTrial = freeTrial;
      });

    this.featureFlags
      .flag$(Flag.ShowMembershipSelect)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((flag) => {
        this.showMemberSelect = flag;
      });
  }

  promptForFuelTypeIfNeeded(vehicle: Nullable<Vehicle>) {
    if (!vehicle?.fuelType && this.forGasOrder) {
      this.promptForFuelType();
    }
  }

  // Flow Logic

  nextPage(): Nullable<FlowPageOptions<FlowPage>> {
    if (!this.form.service) {
      this.serviceDetailShown = true;
      return this.optionsForServiceDetailPage();
    } else if (!this.form.vehicle && !this.form.multipleOrderParams?.length) {
      return this.nextPageVehicleSelection(this.form.service);
    } else if (this.form.service.virtual && this.form.service.slotScheduling) {
      return this.nextPageVirtual();
    } else {
      return this.nextPageMobile(this.form.service.slotScheduling);
    }
  }

  private didShowAppointmentBuilder = false;

  private nextPageVehicleSelection(service: Service): Nullable<FlowPageOptions<FlowPage>> {
    const vehicleCount = this.form.multipleOrderParams?.length;
    if (service.allowMultipleVehicles && !vehicleCount) {
      const multipleServicesAvailable = this.props.serviceGroup.schedulableWith(service).length > 1;
      if (multipleServicesAvailable) {
        this.didShowAppointmentBuilder = true;
        return this.optionsForAppointmentBuilder();
      } else {
        return this.optionsForMultiVehiclePage();
      }
    } else if (!this.form.vehicle && (!vehicleCount || vehicleCount < 2)) {
      return this.optionsForVehicleFlowPage();
    }
  }

  private nextPageVirtual(): Nullable<FlowPageOptions<FlowPage>> {
    return !this.form.slotStartTime ? this.optionsForDayTimePage() : null;
  }

  private nextPageMobile(slotScheduling = false): Nullable<FlowPageOptions<FlowPage>> {
    if (!this.form.days.length) {
      return this.optionsForDaySelectorPage();
    } else if (!this.form.window && !slotScheduling) {
      return this.optionsForTimeWindowPage();
    } else if (!this.form.slotStartTime && slotScheduling) {
      return this.optionsForTimeSlotPage();
    } else if (!this.didFinishPinPage) {
      return this.optionsForPinPage();
    } else if (
      this.isCorporateAddress &&
      !this.form.multipleOrderParams &&
      !this.form.vehicle?.license &&
      !this.addedLicense
    ) {
      return this.optionsForLicensePlatePage();
    }
  }

  async flowDidComplete() {
    const didSelect = await this.promptForMembershipIfNeeded();
    if (didSelect) {
      const { data } = await this.promptForPayment();
      if (data) {
        const accepted = await this.promptForLegalIfNeeded();
        if (accepted) {
          this.saveOrder();
        }
      }
    }
  }

  // Data

  async saveOrder() {
    this.loadingAlertCtrl.showLoader();

    // This workaround avoids a race condition on the backend
    // TODO: Remove after syncronous preauth is implemented
    // https://github.com/yoshiinc/website/issues/12845
    if (!this.form.service?.isInspection && this.form.service?.slotScheduling) {
      const delay = 2000;
      const timeout = new Promise((resolve) => setTimeout(resolve, delay));
      await timeout;
    }

    const createSubscription =
      (this.forGasOrder && this.form.weekly) ||
      (!this.form.multipleOrderParams &&
        !this.form.slotStartTime &&
        !this.forGasOrder &&
        this.form.frequency);
    (createSubscription ? this.createSubscription() : this.createOneTimeOrder()).subscribe(
      (data) => {
        this.onOrderPlaced(data);
        this.promptForPlaceholderVehiclesIfNeeded();
      },
      (error) => this.loadingAlertCtrl.showToastAlert('Error placing order')
    );
  }

  createSubscription() {
    const params = this.createSubscriptionParams();
    return this.orderService
      .createVehicleSubscription(params)
      .pipe(finalize(() => this.loadingAlertCtrl.dismissLoader()));
  }

  private createSubscriptionParams(): VehicleSubscriptionParams {
    const params: VehicleSubscriptionParams = {
      vehicleUid: this.form.vehicle!.uid,
      userAddressUid: this.form.userAddress!.uid,
      startDate: this.datePipe.transform(this.form.date, 'yyyy-MM-dd')!,
      shiftWindow: this.shiftWindow(),
      shiftStartTime: this.form.startTime,
      shiftEndTime: this.form.endTime,
      serviceUid: this.form.service!.uid,
      lat: this.form.coords?.lat(),
      lng: this.form.coords?.lng(),
      deliveryLocationDetails: this.form.note,
      deliveryLocationPersistent: this.form.persistDeliveryLocation,
    };
    if (this.form.service?.serviceType?.name !== ServiceName.Gas) {
      params.frequency = this.form.frequency;
    } else {
      params.days = this.form.days.map((day) => day.dayOfTheWeek.toLowerCase());
    }
    return params;
  }

  createOneTimeOrder() {
    const params = this.createOrderParams();

    return this.createOneTimeOrderRequest(params).pipe(
      finalize(() => {
        this.loadingAlertCtrl.dismissLoader();
      })
    );
  }

  private createOneTimeOrderRequest(params: OrderParams) {
    if (this.form.multipleOrderParams && this.form.multipleOrderParams.length > 0) {
      return this.orderService.createMultiOrders(params);
    } else {
      return this.orderService.createOrder(this.form.vehicle!, params);
    }
  }

  private createOrderParams(): OrderParams {
    const params: OrderParams = {
      vehicleUid: this.form.vehicle?.uid,
      userAddressUid: this.form.userAddress!.uid,
      date: this.datePipe.transform(this.form.date, 'yyyy-MM-dd')!,
      shiftWindow: this.shiftWindow(),
      shiftStartTime: this.form.startTime,
      shiftEndTime: this.form.endTime,
      slotStartDateTime: this.form.slotStartTime!,
      deliveryLocationLat: this.form.coords?.lat(),
      deliveryLocationLng: this.form.coords?.lng(),
      deliveryLocationDetails: this.form.note,
      deliveryLocationPersistent: this.form.persistDeliveryLocation,
      multipleOrderParams: this.form.multipleOrderParams,
    };

    if (!this.form.multipleOrderParams?.length) {
      params.serviceUid = this.form.service?.uid;
    }
    return params;
  }

  private shiftWindow() {
    return this.form.service?.slotScheduling ? ShiftName.SlotScheduling : this.form.window!.title;
  }

  async onOrderPlaced(subscription?) {
    if (!this.props.skipCompletionToast) {
      this.loadingAlertCtrl.showToastConfirmation(`We've placed your order!`);
    }
    this.analytics.trackEvent('Gas Ordered - Client');
    this.uberService.sendEvent(YoshiAppEvent.OrderSuccess);
    this.analytics.trackFacebookPurchaseEvent();
    if (this.onComplete) {
      this.onComplete();
    }
    if (!this.props.skipCompletionModal) {
      this.presentCompletionModal(subscription);
    }
  }

  // Page Presentation

  private async presentCompletionModal(subscription: VehicleSubscription) {
    this.modalService.dismissModal(subscription);
    const orderPlacedDisclaimerModal = await this.modalService.open({
      component: RequestReceivedModalPage,
      props: {
        service: this.form.service,
      },
    });

    orderPlacedDisclaimerModal.onDidDismiss().then(() => {
      this.presentAdditionalFlows();
    });
  }

  private promptForFuelType() {
    setTimeout(() => {
      this.modalService.openPage({
        component: FuelSelectorPage,
        componentProps: {
          onComplete: (fuelType) => this.didSelectFuelType(fuelType),
          onDismiss: () => this.modalService.dismissModal(),
        },
      });
    }, 1000);
  }

  private async presentAdditionalFlows() {
    const sameDayOrder = moment(this.form.date).isSame(moment(), 'd');
    const shouldPresentUpsell = !sameDayOrder;
    if (CapacityDisclaimerPage.ShowForAddress(this.form.userAddress!.address)) {
      this.modalService.open({ component: CapacityDisclaimerPage });
    } else if (shouldPresentUpsell) {
      this.eventsService.publish(OrderFlowEvents.DidComplete, {
        service: this.form.service,
        skipPowerUps: this.props.skipPowerUps,
      });
    }
  }

  private promptForPayment(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (!this.needsCreditCard) {
        return resolve({ data: true });
      }
      const paymentSheet = await this.modalService.openPage(
        {
          component: YshPaymentSheetComponent,
          props: {
            outlineInputStyle: this.props.outlineInputStyle,
          },
        },
        true
      );
      const data = await paymentSheet.onDidDismiss();
      resolve(data);
    });
  }

  private didFinishWithPinPage(result: PinFlowPageForm) {
    this.form.coords = result.mapInfo?.coords;
    this.form.note = result.note;
    this.isCorporateAddress = result.isCorporateAddress || false;
    this.form.persistDeliveryLocation = result.persistDeliveryLocation || false;
    this.didFinishPinPage = true;
    this.flowDirector.next();
  }

  private didFinishWithLicensePlatePage(licensePlate: string) {
    if (licensePlate) {
      this.addedLicense = true;
    }
    this.flowDirector.next();
  }

  private didFinishWithServiceDetailPage(service: Service) {
    this.form.service = service;
    this.flowDirector.next();
  }

  presentCorporatePromoPageIfNeeded() {
    return new Promise<void>((resolve) => {
      this.membershipService.sponsoredFreeMemberBenefit$
        .pipe(take(1))
        .subscribe(async (benefit) => {
          const company = this.form.userAddress?.address?.compound?.company;
          const currentUser = this.userService.currentUser$.value;
          if (CorporatePromoPage.shouldPresent(currentUser, company, benefit)) {
            const modal = await this.modalService.openPage({
              component: CorporatePromoPage,
              componentProps: {
                company,
                isModal: true,
                showSuccess: true,
              },
            });
            modal.onDidDismiss().then(() => resolve());
          } else {
            resolve();
          }
        });
    });
  }

  promptForMembershipIfNeeded(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const converted = this.userService.currentUser$.value?.converted;
      const promptForNewMember = !converted && this.hasFreeTrial && this.showMemberSelect;
      if (this.forGasOrder && (!this.membership || promptForNewMember)) {
        const modal = await this.modalService.openPage({
          component: SelectMembershipPage,
          componentProps: {
            onComplete: (didSelectPlan: boolean) => {
              modal.dismiss();
              resolve(didSelectPlan);
            },
            isModal: true,
          },
        });
      } else {
        // not needed resolve immediately.
        resolve(true);
        return;
      }
    });
  }

  async promptForLegalIfNeeded(): Promise<boolean> {
    if (!this.form.service?.isInspection) {
      return true;
    }
    const modal = await this.modalService.openPage({
      component: LegalModalComponent,
    });
    return (await modal.onDidDismiss()).data;
  }

  private promptForPlaceholderVehiclesIfNeeded(): void {
    this.initPlaceholderInfo();

    if (this.hasPlaceholderVehicles && !this.didShowAppointmentBuilder) {
      this.modalService.openPage({
        component: PlaceholderVehiclePage,
        componentProps: {
          onDismiss: () => {
            this.modalService.dismissModal();
            this.orderService.getOrders();
          },
        },
      });
    }
  }

  private initPlaceholderInfo(): void {
    this.vehicleService.vehicles$.pipe(takeUntil(this.unsubscribe)).subscribe((vehicles) => {
      this.hasPlaceholderVehicles = vehicles.some((v) => v.vehicleModel.placeholder);
    });
  }

  private didSelectMultipleServices(services: Service[], slots: TimeSlot[]) {
    this.form.multipleOrderParams = services.map((item) => {
      return { serviceUid: item.uid };
    });
    const totalDuration: number = services.reduce((sum, service) => sum + service.stopDuration, 0);
    this.form.duration = Service.durationString(totalDuration);
    this.form.availableSlots = slots;
    this.flowDirector.next();
  }

  private didSelectVehicleCount(requestedVehicleCount: number) {
    this.form.multipleOrderParams = Array(requestedVehicleCount).fill({
      serviceUid: this.form.service?.uid,
    });
    const totalDuration: number = (this.form.service?.stopDuration || 0) * requestedVehicleCount;
    this.form.duration = Service.durationString(totalDuration);
    this.flowDirector.next();
  }

  private didSelectDays(days: Day[]) {
    this.form.days = days;
    this.form.date = this.earliestDay(days).nextDate;
    this.flowDirector.next();
  }

  private didSelectTimeWindow(shift: Shift, requestedWindow: RequestedWindow) {
    this.form.window = shift;
    this.form.startTime = requestedWindow.lower;
    this.form.endTime = requestedWindow.upper;
    this.flowDirector.next();
  }

  private didSelectTimeSlot(requestedSlot: TimeSlot) {
    this.form.slotStartTime = requestedSlot.startTime;
    this.form.date = requestedSlot.date;
    this.flowDirector.next();
  }

  private didSelectFuelType(fuelType) {
    const fuelParams: VehicleParams = { fuelTypeUid: fuelType.uid };
    this.vehicleService.updateVehicle(this.form.vehicle!, fuelParams);
    this.modalService.dismissModal();
  }

  didChangeAddress(address: UserAddress) {
    // reset and start over
    this.form.days = [];
    this.form.window = null;
    this.form.slotStartTime = null;
    this.didFinishPinPage = false;
    this.form.userAddress = address;
    this.form.multipleOrderParams = null;
    const options = this.optionsForDaySelectorPage();
    options.props.userAddress = address;
    this.flowDirector.setRoot(options);
  }

  // Page Params
  private optionsForServiceDetailPage(): FlowPageOptions<ServiceDetailPage> {
    return {
      page: ServiceDetailPage,
      onComplete: (service) => this.didFinishWithServiceDetailPage(service),
      onDismiss: () => this.onDismiss?.(),
      preventBackNavigation: this.preventBackNavigation,
      pageProps: {
        serviceGroup: this.props.serviceGroup,
        faqTags: this.props.webflowVariant?.faqTags,
        showConfirm: true,
        hideFuelDiscount: true,
        showCredit: true,
      },
    };
  }

  private optionsForLicensePlatePage(): FlowPageOptions<VehicleLicensePlatePage> {
    return {
      page: VehicleLicensePlatePage,
      onComplete: (licensePlate) => this.didFinishWithLicensePlatePage(licensePlate),
      pageProps: {
        selectedVehicle: this.form.vehicle!,
        allowSkip: false,
      },
    };
  }
  private optionsForPinPage(): FlowPageOptions<PinFlowPage> {
    return {
      page: PinFlowPage,
      onComplete: (result) => this.didFinishWithPinPage(result),
      props: {
        userAddress: this.addressService.selectedAddress$.value!,
        service: this.form.service!,
        onAddressChanged: (address: UserAddress) => this.didChangeAddress(address),
      },
    };
  }
  private optionsForDaySelectorPage(): FlowPageOptions<DaySelectorPage> {
    return {
      page: DaySelectorPage,
      onComplete: (days: Day[], repeatWeekly: boolean, frequency: number) => {
        this.didSelectDays(days);
        this.form.weekly = repeatWeekly;
        this.form.frequency = frequency;
      },
      onDismiss: () => {
        this.form.days = [];
        !this.serviceDetailShown && this.onDismiss?.();
      },
      preventBackNavigation:
        !this.form.service?.allowMultipleVehicles &&
        !this.serviceDetailShown &&
        this.preventBackNavigation,
      props: {
        userAddress: this.form.userAddress!,
        service: this.form.service!,
        days: [],
        showCutoff: this.forGasOrder,
        allowFrequencySelect: true,
        editing: false,
        multipleOrderParams: this.form.multipleOrderParams,
      },
    };
  }
  private optionsForTimeWindowPage(): FlowPageOptions<TimeWindowSelectorPage> {
    return {
      page: TimeWindowSelectorPage,
      onComplete: (shift, requestedWindow) => this.didSelectTimeWindow(shift, requestedWindow),
      onDismiss: () => (this.form.window = null),
      props: {
        day: this.form.days[0],
        cutoffTime: this.loyaltyService.computeCutoffTime,
        service: this.form.service!,
        onAddressChanged: (address: UserAddress) => this.didChangeAddress(address),
        isModal: false,
      },
    };
  }
  private optionsForDayTimePage(): FlowPageOptions<DayTimeSelectorPage> {
    return {
      page: DayTimeSelectorPage,
      onComplete: (requestedSlot) => this.didSelectTimeSlot(requestedSlot),
      onDismiss: () => {
        this.form.slotStartTime = null;
        !this.serviceDetailShown && this.onDismiss?.();
      },
      preventBackNavigation:
        !this.form.service?.allowMultipleVehicles &&
        !this.serviceDetailShown &&
        this.preventBackNavigation,
      props: {
        userAddress: this.form.userAddress,
        service: this.form.service!,
        vehicleCount: this.form.multipleOrderParams?.length || 1,
        timeSlots: this.form.availableSlots,
        duration: this.form.duration,
        multipleOrderParams: this.form.multipleOrderParams,
      },
    };
  }
  private optionsForTimeSlotPage(): FlowPageOptions<TimeSlotSelectorPage> {
    return {
      page: TimeSlotSelectorPage,
      onComplete: (requestedSlot) => this.didSelectTimeSlot(requestedSlot),
      onDismiss: () => (this.form.slotStartTime = null),
      props: {
        userAddress: this.form.userAddress,
        service: this.form.service!,
        day: this.form.days[0],
        vehicleCount: this.form.multipleOrderParams?.length,
        onAddressChanged: (address: UserAddress) => this.didChangeAddress(address),
        multipleOrderParams: this.form.multipleOrderParams,
        isModal: false,
      },
    };
  }

  private optionsForMultiVehiclePage(): FlowPageOptions<MultiVehiclePage> {
    return {
      page: MultiVehiclePage,
      onComplete: (requestedVehicleCount) => this.didSelectVehicleCount(requestedVehicleCount),
      onDismiss: () => (this.form.multipleOrderParams = null),
      preventBackNavigation: !this.serviceDetailShown,
      props: {
        userAddress: this.form.userAddress,
        service: this.form.service!,
        onAddressChanged: (address: UserAddress) => this.didChangeAddress(address),
        isModal: false,
      },
    };
  }

  private optionsForAppointmentBuilder(): FlowPageOptions<AppointmentBuilderPage> {
    return {
      page: AppointmentBuilderPage,
      onComplete: (services, slots) => this.didSelectMultipleServices(services, slots),
      onDismiss: () => (this.form.multipleOrderParams = null),
      preventBackNavigation: !this.serviceDetailShown,
      props: {
        service: this.form.service!,
        serviceGroup: this.props.serviceGroup,
        address: this.form.userAddress,
      },
    };
  }

  private optionsForVehicleFlowPage(): FlowPageOptions<VehicleFlowController> {
    return {
      page: VehicleFlowController,
      onComplete: (selectedVehicle) => this.didSelectVehicle(selectedVehicle),
      onDismiss: () => (this.form.vehicle = null),
      props: {
        promptForConnectedCar: false,
        promptForFuel: false,
        promptForLicense: false,
        promptForNickname: false,
        isModal: false,
      },
    };
  }

  private didSelectVehicle(vehicle: Vehicle) {
    this.form.vehicle = vehicle;
    this.flowDirector.next();
  }
  // Helpers

  earliestDay(days: Day[]): Day {
    const sorted = days.sort((a, b) => a.nextDate.getTime() - b.nextDate.getTime());
    return sorted[0];
  }
}
