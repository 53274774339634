import { Component, Input } from '@angular/core';

@Component({
  selector: 'ysh-loading-overlay',
  templateUrl: 'ysh-loading-overlay.html',
  styleUrls: ['./ysh-loading-overlay.scss'],
})

export class YshLoadingOverlayComponent {
  @Input() spinnerName?: string;
}
