import { IonicModule } from "@ionic/angular";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ComponentsModule } from "src/app/components/components.module";
import { AppointmentBuilderPage } from "./appointment-builder.page";
import { AppointmentBuilderPageRoutingModule } from "./appointment-builder-routing.module";

@NgModule({
  imports: [IonicModule, CommonModule, ComponentsModule, AppointmentBuilderPageRoutingModule],
  declarations: [AppointmentBuilderPage]
})
export class AppointmentBuilderPageModule {}
