import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ysh-service-added-card',
  templateUrl: 'service-added-card.html',
  styleUrls: ['./service-added-card.scss'],
})
export class ServiceAddedCardComponent {
  @Input() serviceTitle: string;
  @Input() servicePrice: number;
  @Input() serviceDuration: number;
  @Input() allowDelete: boolean;
  @Output() onTapDelete = new EventEmitter();

  constructor() {}

  didTapDelete() {
    this.onTapDelete.emit();
  }
}
