import { Component, Input, OnInit } from '@angular/core';

import { AddressService } from 'src/app/services/api/address/address.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { FlowPage } from '../flow-director';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { Service } from 'src/app/models/service';
import { UserAddress } from 'src/app/models/user-address';

export interface MultiVehiclePageProps {
  service: Service;
  userAddress: UserAddress;
  isModal: boolean;
  selectedVehicleCount?: number;
  onAddressChanged: (address: UserAddress) => void;
}

export interface MultiVehiclePageForm {
  vehicleCount?: number;
  maxGroupableVehicles?: number;
}

@Component({
  selector: 'multi-vehicle',
  templateUrl: 'multi-vehicle.page.html',
  styleUrls: ['multi-vehicle.page.scss'],
})
export class MultiVehiclePage implements OnInit, FlowPage {
  @Input() onComplete: (requestedVehicleCount: number) => void;
  @Input() onDismiss: () => void;
  @Input() preventBackNavigation?: boolean | undefined;
  @Input() selectOptions: any = {
    header: 'Number of Vehicles',
    cssClass: 'alert-wide',
  };
  props: MultiVehiclePageProps;
  form: MultiVehiclePageForm = {};
  vehicleCountOptions: string[] = [];

  readonly MAX_VEHICLES_FOR_TIMEWINDOW_SCHEDULE = 5;

  constructor(
    private analytics: AnalyticsService,
    private modalService: ModalService,
    private loadingAlertCtrl: LoadingAlertService,
    private addressService: AddressService
  ) {}

  ngOnInit() {
    this.analytics.trackView('MultiVehiclePage');
    this.loadMaxGroupableVehicles();
    this.form.vehicleCount = this.getDefaultVehicleCount();
  }

  // Data

  loadMaxGroupableVehicles() {
    if (!this.props.service.slotScheduling) {
      this.form.maxGroupableVehicles = this.MAX_VEHICLES_FOR_TIMEWINDOW_SCHEDULE;
      return;
    }
    this.addressService
      .getMaxVehicleCount(this.props.userAddress, null, null, this.props.service)
      .subscribe(
        (maxGroupableVehicles) => {
          this.setMaxGroupableVehicles(maxGroupableVehicles);
        },
        (error) => {
          this.loadingAlertCtrl.showToastAlert(`Error getting max groupable vehicles: ${error}`);
        }
      );
  }

  // Actions

  didTapOnVehicleCount(vehicleCount: number) {
    this.form.vehicleCount = vehicleCount;
  }

  didSelectVehicleCount(requestedVehicleCount: number) {
    this.onComplete?.(requestedVehicleCount);
  }

  didTapDismiss() {
    this.modalService.dismissModal();
  }

  didUpdateAddress(userAddress: UserAddress) {
    this.props.onAddressChanged?.(userAddress);
  }

  didTapBack() {
    this.onDismiss?.();
  }

  // Helpers

  isSelected(vehicleCount: number) {
    return this.form.vehicleCount === vehicleCount;
  }

  setMaxGroupableVehicles(maxGroupableVehicles) {
    this.form.maxGroupableVehicles = maxGroupableVehicles;
    if (this.form.maxGroupableVehicles && this.form.maxGroupableVehicles >= 1) {
      this.vehicleCountOptions = Array.from(Array(this.form.maxGroupableVehicles).keys()).map(
        (i) => {
          return this.props.service.vehicleGroupServiceTitle(i + 1);
        }
      );
    } else {
      this.vehicleCountOptions = [];
    }
  }

  private getDefaultVehicleCount(): number {
    return this.props.selectedVehicleCount || 1;
  }
}
