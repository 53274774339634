import { RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { WebflowStartPage } from './webflow-start.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'uber',
    redirectTo: 'uber/san-francisco',
  },
  {
    path: 'uber/las-vegas',
    redirectTo: 'uber/virtual/nevada',
  },
  {
    path: 'uber/san-francisco',
    component: WebflowStartPage,
    data: {
      webflowVariant: 'uber',
      webflowVariantArea: 'san_francisco'
    },
  },
  {
    path: 'uber/los-angeles',
    component: WebflowStartPage,
    data: {
      webflowVariant: 'uber',
      webflowVariantArea: 'los_angeles'
    },
  },
  {
    path: 'uber/virtual/arkansas',
    component: WebflowStartPage,
    data: {
      webflowVariant: 'uber_virtual',
      webflowVariantArea: 'arkansas'
    },
  },
  {
    path: 'uber/virtual/alabama',
    component: WebflowStartPage,
    data: {
      webflowVariant: 'uber_virtual',
      webflowVariantArea: 'alabama'
    },
  },
  {
    path: 'uber/virtual/michigan',
    component: WebflowStartPage,
    data: {
      webflowVariant: 'uber_virtual',
      webflowVariantArea: 'michigan'
    },
  },
  {
    path: 'uber/virtual/new-mexico',
    component: WebflowStartPage,
    data: {
      webflowVariant: 'uber_virtual',
      webflowVariantArea: 'new_mexico'
    },
  },
  {
    path: 'uber/virtual/nebraska',
    component: WebflowStartPage,
    data: {
      webflowVariant: 'uber_virtual',
      webflowVariantArea: 'nebraska'
    },
  },
  {
    path: 'uber/virtual/nevada',
    component: WebflowStartPage,
    data: {
      webflowVariant: 'uber_virtual',
      webflowVariantArea: 'nevada'
    },
  },
  {
    path: 'uber/virtual/vermont',
    component: WebflowStartPage,
    data: {
      webflowVariant: 'uber_virtual',
      webflowVariantArea: 'vermont'
    },
  },
  {
    path: 'uber/virtual/maine',
    component: WebflowStartPage,
    data: {
      webflowVariant: 'uber_virtual',
      webflowVariantArea: 'maine'
    },
  },
  {
    path: 'uber/virtual/alaska',
    component: WebflowStartPage,
    data: {
      webflowVariant: 'uber_virtual',
      webflowVariantArea: 'alaska'
    },
  },
  {
    path: 'uber/virtual/arizona',
    component: WebflowStartPage,
    data: {
      webflowVariant: 'uber_virtual',
      webflowVariantArea: 'arizona'
    },
  },
  {
    path: 'turo/virtual',
    component: WebflowStartPage,
    data: {
      webflowVariant: 'turo_virtual',
    },
  },
  {
    path: 'turo/inspections',
    component: WebflowStartPage,
    data: {
      webflowVariant: 'turo_inspections',
    },
  },
  {
    path: 'getaround/virtual',
    component: WebflowStartPage,
    data: {
      webflowVariant: 'getaround_virtual',
    },
  },
  {
    path: 'inspections/dot',
    component: WebflowStartPage,
    data: {
      webflowVariant: 'dot_inspections',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WebflowStartPageRoutingModule {}
