import { Component, Input, OnInit } from '@angular/core';
import { map, tap } from 'rxjs/operators';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { Observable } from 'rxjs';
import { Vehicle } from 'src/app/models/vehicle';
import { VehicleFlowController } from '../../flows/vehicle/vehicle-flow-controller/vehicle-flow-controller.page';
import { VehiclesService } from 'src/app/services/api/vehicles/vehicles.service';

@Component({
  selector: 'placeholder-vehicle',
  templateUrl: 'placeholder-vehicle.page.html',
  styleUrls: ['placeholder-vehicle.page.scss'],
})
export class PlaceholderVehiclePage implements OnInit {
  @Input() onComplete: () => void;
  @Input() onDismiss: () => void;
  @Input() selectOptions: any = {
    header: 'Number of Vehicles',
  };

  placeholderVehicles$: Observable<Nullable<Vehicle[]>>;
  didUpdateVehicle = false;
  currentPage: number = 1;

  private vehicleCache: { [key: string]: boolean } = {};

  constructor(
    private vehicleService: VehiclesService,
    private analytics: AnalyticsService,
    private modalService: ModalService
  ) {
    this.loadVehicles();
  }

  ngOnInit() {
    this.analytics.trackView('PlaceholderVehiclePage');
  }

  // Data

  loadVehicles() {
    this.placeholderVehicles$ = this.vehicleService.vehicles$.pipe(
      map((data) => data.filter((vehicle) => this.displayVehicle(vehicle))),
      map((data) => data.sort((a, b) => a.uid.localeCompare(b.uid))),
      tap((data) => data?.forEach((vehicle) => (this.vehicleCache[vehicle.uid] = true)))
    );
  }

  private displayVehicle(vehicle: Vehicle): boolean {
    return vehicle.vehicleModel.placeholder || this.vehicleCache[vehicle.uid];
  }

  // Actions

  didTapBack() {
    this.onDismiss?.();
  }

  async didTapVehicle(vehicle: Vehicle) {
    const modal = await this.modalService.openPage({
      component: VehicleFlowController,
      componentProps: {
        onComplete: () => {
          modal.dismiss();
          this.didUpdateVehicle = true;
        },
        onDismiss: () => modal.dismiss(),
      },
      props: {
        isModal: true,
        existingVehicle: vehicle,
        promptForFuel: false,
        promptForLicense: false,
        promptForNickname: false,
      },
    });
  }

  didTapSkipForNow() {
    this.onDismiss?.();
  }
}
