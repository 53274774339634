import { Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { Service } from 'src/app/models/service';

@Component({
  selector: 'ysh-service-selector-page',
  templateUrl: 'service-selector.page.html',
  styleUrls: ['./service-selector.page.scss'],
})
export class ServiceSelectorPage {
  @Input() services: Service[];
  @Input() onComplete: (index: number) => void;

  selectedIndex: number;

  constructor(private modalCtrl: ModalController) {}

  didTapDismiss() {
    this.modalCtrl.dismiss(false);
  }
  didTapCard(index: number) {
    this.selectedIndex = index;
    this.onComplete(index);
  }
}
