import { BaseEntity, MemoizeGetters, Transform, toDate } from './base';
import moment, { MomentInput } from 'moment-timezone';

@MemoizeGetters
export class TimeSlot extends BaseEntity {
  startTime: string;
  available: boolean;

  @Transform(toDate)
  date: Date;

  get startTimeString() {
    return toTimeString(this.startTime, true);
  }

  get dateString() {
    return this.date.toDateString();
  }
}

export function toTimeString(value: MomentInput, inZone = false, timeZone?: string): string {
  const parser = inZone ? moment.parseZone : moment;
  const parsed = timeZone ? parser(value).tz(timeZone) : parser(value);
  const format = timeZone ? 'h:mma z' : 'h:mma';
  return parsed.format(format);
}
