export interface VirtualDefaultLocation {
  locationName: string;
  lat: number;
  lng: number;
  zipCode: string;
}

export interface UberAreaProps {
  headerCaption?: string;
  serviceDaysWindow: string;
  serviceTimeWindow: string;
  servicePrice: string;
  serviceName?: string;
  displayName?: string;
  defaultLocation?: VirtualDefaultLocation;
}

export const uberAreas: { [key: string]: UberAreaProps } = {
  san_francisco: {
    serviceDaysWindow: 'daily (except Sundays)',
    serviceTimeWindow: '9am-5pm',
    servicePrice: '$45',
  },
  los_angeles: {
    serviceDaysWindow: 'Monday to Friday',
    serviceTimeWindow: '9am-5pm',
    servicePrice: '$45',
  },
  arkansas: {
    serviceDaysWindow: 'daily (except Sunday)',
    serviceTimeWindow: '10am-7pm CT',
    servicePrice: '$30',
    serviceName: 'arkansas',
    displayName: 'Arkansas',
    defaultLocation: {
      locationName: 'Arkansas, USA',
      lat: 34.746483,
      lng: -92.289597,
      zipCode: '72201',
    },
  },
  michigan: {
    serviceDaysWindow: 'daily (except Sunday)',
    serviceTimeWindow: '11am-8pm ET',
    servicePrice: '$30',
    serviceName: 'michigan',
    displayName: 'Michigan',
    defaultLocation: {
      locationName: 'Michigan, USA',
      lat: 42.7336,
      lng: -84.5553,
      zipCode: '48933',
    },
  },
  new_mexico: {
    serviceDaysWindow: 'daily (except Sunday)',
    serviceTimeWindow: '8am-6pm MT',
    servicePrice: '$30',
    serviceName: 'new mexico',
    displayName: 'New Mexico',
    defaultLocation: {
      locationName: 'New Mexico, USA',
      lat: 35.6823,
      lng: -105.9396,
      zipCode: '87501',
    },
  },
  nebraska: {
    serviceDaysWindow: 'daily (except Sunday)',
    serviceTimeWindow: '10am-7pm CT',
    servicePrice: '$30',
    serviceName: 'nebraska',
    displayName: 'Nebraska',
    defaultLocation: {
      locationName: 'Nebraska, USA',
      lat: 41.2565,
      lng: -95.9345,
      zipCode: '68102',
    },
  },
  nevada: {
    serviceDaysWindow: 'daily (except Sunday)',
    serviceTimeWindow: '8am-5pm PT',
    servicePrice: '$30',
    serviceName: 'nevada',
    displayName: 'Nevada',
    defaultLocation: {
      locationName: 'Nevada, USA',
      lat: 39.164,
      lng: -119.7668,
      zipCode: '89701',
    },
  },
  vermont: {
    serviceDaysWindow: 'daily (except Sunday)',
    serviceTimeWindow: '11am-8pm ET',
    servicePrice: '$30',
    serviceName: 'vermont',
    displayName: 'Vermont',
    defaultLocation: {
      locationName: 'Vermont, USA',
      lat: 44.2606,
      lng: -72.5778,
      zipCode: '05601',
    },
  },
  maine: {
    serviceDaysWindow: 'daily (except Sunday)',
    serviceTimeWindow: '11am-8pm ET',
    servicePrice: '$30',
    serviceName: 'maine',
    displayName: 'Maine',
    defaultLocation: {
      locationName: 'Maine, USA',
      lat: 44.3106,
      lng: -69.7795,
      zipCode: '04330',
    },
  },
  alaska: {
    serviceDaysWindow: 'daily (except Sunday)',
    serviceTimeWindow: '7am-4pm AKT',
    servicePrice: '$30',
    serviceName: 'alaska',
    displayName: 'Alaska',
    defaultLocation: {
      locationName: 'Alaska, USA',
      lat: 61.2165,
      lng: -149.8876,
      zipCode: '99501',
    },
  },
  arizona: {
    headerCaption: '*Required for vehicles 10 years or older',
    serviceDaysWindow: 'daily (except Sunday)',
    serviceTimeWindow: '9am-6pm MST',
    servicePrice: '$30',
    serviceName: 'arizona',
    displayName: 'Arizona',
    defaultLocation: {
      locationName: 'Arizona, USA',
      lat: 33.4484,
      lng: -112.0741,
      zipCode: '85001',
    },
  },
  alabama: {
    serviceDaysWindow: 'daily (except Sunday)',
    serviceTimeWindow: '10am-7pm CT',
    servicePrice: '$30',
    serviceName: 'alabama',
    displayName: 'Alabama',
    defaultLocation: {
      locationName: 'Alabama, USA',
      lat: 32.3669,
      lng: -86.3006,
      zipCode: '36104',
    },
  },
};
