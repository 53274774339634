import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Vehicle } from 'src/app/models/vehicle';

@Component({
  selector: 'ysh-vehicle-profile-information',
  templateUrl: './vehicle-profile-information.component.html',
  styleUrls: ['./vehicle-profile-information.component.scss'],
})
export class VehicleProfileInformationComponent {
  @Output() onDidTapEdit: EventEmitter<string> = new EventEmitter();

  @Input() vehicle: Vehicle;

  get missingInfo(): string {
    if (!this.vehicle.hasCustomNickname) {
      return 'nickname';
    }
    if (!this.vehicle.license) {
      return 'license';
    }
    if (!this.vehicle.vin) {
      return 'vin';
    }

    return '';
  }

  didTapEdit() {
    this.onDidTapEdit.emit(this.missingInfo);
  }
}
