import { EnvironmentService, ServerEnvironment } from '../environment/environment.service';

import { ApiService } from '../api/api.service';
import { Facebook } from '@ionic-native/facebook/ngx';
import { FeatureFlagService } from '../feature-flag/feature-flag.service';
import { Injectable } from '@angular/core';
import { LoadingAlertService } from '../loading-alert/loading-alert.service';
import { Platform } from '@ionic/angular';
import { SessionService } from '../session/session.service';
import { UserService } from '../api/user/user.service';
import { v4 as uuid } from 'uuid';

declare var gtag: any;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  static readonly USER_ATTRIBUTION_KEY = 'user_attribution';

  constructor(
    private platform: Platform,
    private api: ApiService,
    private userService: UserService,
    private session: SessionService,
    private loadingAlert: LoadingAlertService,
    private fb: Facebook,
    private environment: EnvironmentService,
    private featureFlags: FeatureFlagService
  ) {}

  setup() {
    this.userService.currentUser$.subscribe((user) => {
      if (user) {
        this.setUser(user.uid);
        this.handleStoredDeeplink();
      }
    });
  }

  setUser(uid) {
    if (this.platform.is('cordova')) {
      this.platform.ready().then(() => {
        const Branch = window['Branch'];
        if (Branch) {
          Branch.setIdentity(uid);
        }
      });
    }
  }

  trackView(title: string) {
    this.trackGooglePageView(title);
    if (this.userService.isLoggedIn$.value) {
      this.trackServerPageView(title);
      this.featureFlags.trackEvent('Page View: ' + title);
      console.log('Server: track event', title);
    }
  }

  trackEvent(eventName: string) {
    if (this.platform.is('cordova')) {
      this.platform.ready().then(() => {
        const Branch = window['Branch'];
        if (Branch) {
          Branch.userCompletedAction(eventName, null);
        }
        this.fb.logEvent(eventName).then(
          (result) => {
            console.log('Tracked FB event', eventName);
          },
          (error) => {
            console.log('Error tracking FB event', error);
          }
        );
        this.featureFlags.trackEvent(eventName);
      });
      console.log('GA: track event', eventName);
    }
    if (this.userService.isLoggedIn$.value) {
      this.trackServerEvent(eventName);
      console.log('Server: track event', eventName);
    }
  }

  trackFacebookPurchaseEvent() {
    if (this.platform.is('cordova')) {
      this.platform.ready().then(() => {
        // Constant defined in IonicNative/Facebook, but using it causes an error
        this.fb.logEvent('fb_mobile_purchase').then(() => {
          console.log('Tracked FB purchase');
        });
      });
    }
  }

  private trackServerEvent(eventName: string) {
    this.api
      .call({
        url: '/analytics',
        method: 'POST',
        body: { event_name: eventName },
      })
      .subscribe(
        (response) => {
          console.log('Tracked server event');
        },
        (error) => {
          console.log('Error tracking event', error);
        }
      );
  }

  private trackServerPageView(pageName: string) {
    this.api
      .call({
        url: '/page_views',
        method: 'POST',
        body: { pageName },
      })
      .subscribe(
        (response) => {
          console.log('Tracked server page view');
        },
        (error) => {
          console.log('Error tracking page view', error);
        }
      );
  }

  handleDeeplink(data: DeepLinkData) {
    const user = this.userService.currentUser$.getValue();
    const user_uid = data.user_uid;
    const auth_key = data.auth_key;

    if (user_uid && auth_key) {
      this.loadingAlert.showLoaderWithText('Logging in');
      this.userService
        .logout()
        .then(() => {
          if (data.environment === 'beta') {
            return this.environment.switchServer(ServerEnvironment.Beta);
          }
        })
        .then(() => {
          this.userService.loginWithAuthData({ auth_key, user_uid }).then(
            () => {
              this.loadingAlert.dismissLoader();
            },
            () => {
              this.loadingAlert.dismissLoader();
              this.loadingAlert.showToastAlert('Error logging in');
            }
          );
        });
    } else if (user) {
      this.attributeUser(data);
    } else {
      this.session.set(AnalyticsService.USER_ATTRIBUTION_KEY, data);
    }
  }

  private handleStoredDeeplink() {
    this.session.get(AnalyticsService.USER_ATTRIBUTION_KEY).then((data: DeepLinkData) => {
      if (data) {
        this.handleDeeplink(data);
        this.session.remove(AnalyticsService.USER_ATTRIBUTION_KEY);
      }
    });
  }

  private attributeUser(data: DeepLinkData) {
    const stringifiedData = JSON.stringify(data);
    this.userService.updateUser({
      ref: data.ref,
      deepLinkData: stringifiedData,
    });
  }

  private trackGooglePageView(page_title: string) {
    gtag('event', 'page_view', {
      page_title,
    });
  }
}

interface DeepLinkData {
  user_uid?: string;
  auth_key?: string;
  ref?: string;
  environment?: 'beta' | 'prod';
}
