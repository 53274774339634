import * as moment from 'moment';

import { BaseEntity, MemoizeGetters, Transform, toDate, toEntity } from './base';
import { Eta, EtaStatus } from './eta';
import { Shift, ShiftName, ShiftTime } from './shift';

import { Address } from './address';
import { DeliveryLocation } from './delivery-location';
import { FuelType } from './fuel-type';
import { Route } from './route';
import { Service } from './service';
import { UserAddress } from './user-address';
import { Vehicle } from './vehicle';
import { toTimeString } from './time-slot';

export enum OrderStatus {
  Pending = 'pending',
  Claimed = 'claimed',
  Pumping = 'pumping',
  Complete = 'complete',
  Cancelled = 'cancelled',
  Proposed = 'proposed',
  Rejected = 'rejected',
  Deleted = 'deleted',
  MissingPayment = 'missing_valid_card',
}

export enum OrderCancelReason {
  CustomerRequested = 'customer_requested',
  VehicleMissing = 'vehicle_missing',
  GasFlapLocked = 'gas_flap_locked',
  VehicleHoodLocked = 'vehicle_hood_locked',
  VehicleDeleted = 'vehicle_deleted',
  MissingPayment = 'missing_valid_payment_method',
  Rescheduled = 'order_rescheduled',
  Missed = 'missed',
  SkipNextDelivery = 'skip_next_delivery',
  SubscriptionCancelled = 'subscription_cancelled',
}

@MemoizeGetters
export class Order extends BaseEntity implements ShiftStartEnd {
  shiftStartTime: ShiftTime;
  shiftEndTime: ShiftTime;
  slotStartDateTime?: string;
  status: OrderStatus;
  shiftWindow: ShiftName;
  sourceType?: 'VehicleSubscription' | 'User';
  sourceUid?: string;
  truckUid: string;
  routeId: string;
  receiptSent: boolean;
  needsPin: boolean;

  @Transform(toDate)
  date: Date;

  @Transform(toDate)
  timeCompleted: Date;

  @Transform(toEntity(FuelType))
  fuelType: FuelType;

  @Transform(toEntity(Vehicle))
  vehicle: Vehicle;

  @Transform(toEntity(UserAddress))
  userAddress: UserAddress;

  @Transform(toEntity(Address))
  address: Address;

  @Transform(toEntity(DeliveryLocation))
  deliveryLocation: DeliveryLocation;

  @Transform(toEntity(Eta))
  eta: Eta;

  @Transform(toEntity(Service))
  service: Service;

  @Transform(toDate)
  locationConfirmedAt: Date;

  constructor(json) {
    // REFACTOR: this is a patch to remap address onto user_address object to keep local model consistent
    // Consider requesting a change the api
    if (json.userAddress) {
      json.userAddress.address = json.address;
    }
    super(json);

    this.shiftStartTime = this.shiftStartTime || Shift.defaultStartTime(this.shiftWindow);
    this.shiftEndTime = this.shiftEndTime || Shift.defaultEndTime(this.shiftWindow);
    if (!this.deliveryLocation && this.userAddress) {
      this.deliveryLocation = this.deliveryLocationFromAddress(this.userAddress.address);
    }
  }

  // Computed Properties

  get isCancellable() {
    return CANCELLEABLE_STATUSES.includes(this.status);
  }

  get isScheduled() {
    return SCHEDULED_STATUSES.includes(this.status);
  }

  get isFromSubscription() {
    return this.sourceType === 'VehicleSubscription';
  }

  get etaStatus(): EtaStatus {
    const shiftStart = moment(this.shiftStartTime);
    const shiftEnd = moment(this.shiftEndTime);
    if (!this.isToday || !this.eta) {
      return EtaStatus.NotAvailable;
    }
    if (shiftStart.isAfter(this.eta.etaStart)) {
      return EtaStatus.RunningEarly;
    }
    if (shiftEnd.isBefore(this.eta.etaEnd)) {
      return EtaStatus.RunningLate;
    }
    return EtaStatus.OnTime;
  }

  get shift(): Shift | undefined {
    const matchingRoute = this.availableRoutesOnOrderDate.find((route) => {
      return route.shift.title === this.shiftWindow;
    });
    return matchingRoute?.shift;
  }

  get isToday(): boolean {
    return (
      !UNSCHEDULED_STATUES.includes(this.status) &&
      new Date().toDateString() === this.date.toDateString()
    );
  }

  get isTomorrow() {
    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);
    return tomorrow.toDateString() === this.date.toDateString();
  }

  get availableRoutesOnOrderDate(): Route[] {
    if (this.userAddress && this.userAddress.days) {
      for (const day of this.userAddress.days) {
        if (this.date.getDay() === day.dayIndex) {
          return day.routes;
        }
      }
    }
    return [];
  }

  get isOrderLocationPersistent(): boolean {
    return this.deliveryLocation && this.deliveryLocation.persistent;
  }

  shiftTimeString(withMinutes?) {
    return createShiftWindowString(this, withMinutes);
  }

  get slotTimeString(): string {
    return this.slotStartDateTime
      ? toTimeString(this.slotStartDateTime, true, this.address.timeZone)
      : '';
  }

  private deliveryLocationFromAddress(address: Address) {
    return new DeliveryLocation({
      lat: address.lat,
      lng: address.lng,
      locationName: address.locationName,
    });
  }
}

const CANCELLEABLE_STATUSES = [
  OrderStatus.Pending,
  OrderStatus.Proposed,
  OrderStatus.Claimed,
  OrderStatus.MissingPayment,
];

const SCHEDULED_STATUSES = [
  OrderStatus.Pending,
  OrderStatus.Proposed,
  OrderStatus.Claimed,
  OrderStatus.MissingPayment,
];

const UNSCHEDULED_STATUES = [OrderStatus.Cancelled, OrderStatus.Rejected];

export interface ShiftStartEnd {
  shiftStartTime?: ShiftTime;
  shiftEndTime?: ShiftTime;
}

export interface OrderParams {
  vehicleUid?: string;
  userAddressUid?: string;
  date?: string;
  shiftWindow?: string;
  shiftStartTime?: number;
  shiftEndTime?: number;
  slotStartDateTime?: string;
  frequency?: number;
  quote?: boolean;
  serviceUid?: string;
  deliveryLocationLat?: number;
  deliveryLocationLng?: number;
  deliveryLocationDetails?: string;
  deliveryLocationPersistent?: boolean;
  multipleOrderParams?: Nullable<MultipleOrderParams[]>;
}

export interface MultipleOrderParams {
  serviceUid: string;
}

export const createShiftWindowString = (obj: ShiftStartEnd, minutes = false) => {
  const format = minutes ? 'h:mma' : 'ha';
  const start = obj.shiftStartTime ? moment(obj.shiftStartTime).format(format) : '';
  const end = obj.shiftEndTime ? moment(obj.shiftEndTime).format(format) : '';
  return start && end ? `${start} - ${end}` : '';
};
