import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MultiVehiclePage } from './multi-vehicle.page';
import { MultiVehiclePageRoutingModule } from './multi-vehicle-routing.module';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ComponentsModule,
    MultiVehiclePageRoutingModule,
  ],
  declarations: [MultiVehiclePage],
})
export class MultiVehiclePageModule {}
