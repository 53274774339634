import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPhoneNumber',
})
export class FormatPhoneNumberPipe implements PipeTransform {
  transform(phoneNumber?: string) {
    // (123) 456-7890
    if (phoneNumber) {
      return phoneNumber.replace(/\+\d{1}/, '').replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
  }
}
