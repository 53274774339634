import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'ysh-legal-text',
  templateUrl: 'ysh-legal-text.html',
  styleUrls: ['./ysh-legal-text.scss'],
})

export class YshLegalTextComponent {

  constructor(public platform: Platform) {};

  openLink(link: string) {
    if (this.platform.is('mobile')) {
      window.open(link, '_system', 'location=yes');
    } else {
      window.open(link);
    }
    return false;
  }
}
