import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { PlaceholderVehiclePage } from './placeholder-vehicle.page';
import { PlaceholderVehiclePageRoutingModule } from './placeholder-vehicle-routing.module';

@NgModule({
  imports: [IonicModule, CommonModule, ComponentsModule, PlaceholderVehiclePageRoutingModule],
  declarations: [PlaceholderVehiclePage],
})
export class PlaceholderVehiclePageModule {}
