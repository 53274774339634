import { Component, Input, OnInit } from '@angular/core';
import {
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
  StripeElements,
  TokenResult,
} from '@stripe/stripe-js';
import { finalize, flatMap } from 'rxjs/operators';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { StripeService } from 'src/app/services/stripe/stripe';
import { UserService } from 'src/app/services/api/user/user.service';
import { from } from 'rxjs';

@Component({
  selector: 'ysh-credit-card-flow',
  templateUrl: './credit-card-flow.page.html',
  styleUrls: ['./credit-card-flow.page.scss'],
})
export class CreditCardFlowPage implements OnInit {
  @Input() hideBackButton = true;
  @Input() showDismissBtn = false;
  @Input() showInDarkTheme: boolean;
  @Input() outlineInputStyle = false;

  elements?: StripeElements;

  cardElement: StripeCardNumberElement;
  expiryElement: StripeCardExpiryElement;
  cvcElement: StripeCardCvcElement;
  postalCode;

  cardNumberError: Nullable<string>;
  cardExpiryError: Nullable<string>;
  cardCvcError: Nullable<string>;

  cardNumberComplete = false;
  cardExpiryComplete = false;
  cardCvcComplete = false;

  constructor(
    private loadingAlertCtrl: LoadingAlertService,
    private userService: UserService,
    private analytics: AnalyticsService,
    private modalService: ModalService,
    private stripeService: StripeService
  ) {}

  ngOnInit() {
    this.analytics.trackView('CreditCardFlowPage');

    var styleBaseUnderline = {
      base: {
        color: '#597593',
        fontWeight: 700,
        fontFamily: "'Museo Sans', sans-serif",
        fontSize: '18px',
        ':focus': {
          color: '#597593',
        },
        '::placeholder': {
          color: '#9BACC8',
        },
        ':focus::placeholder': {
          color: '#CFD7DF',
        },
      },
    };

    var styleBaseOutline = {
      base: {
        color: '#142846',
        fontWeight: 300,
        fontFamily: "'Museo Sans', sans-serif",
        fontSize: '15px',
        lineHeight: '1.5',
        ':focus': {
          color: '#142846',
        },
        '::placeholder': {
          color: '#5A6E8C',
        },
        ':focus::placeholder': {
          color: '#142846',
        },
      },
    };

    var styleInvalid = {
      invalid: {
        color: '#FF6D5B',
        ':focus': {
          color: '#FF6D5B',
        },
        '::placeholder': {
          color: '#FFCCA5',
        },
      },
    };

    var classes = {
      focus: 'focus',
      empty: 'empty',
      invalid: 'invalid',
    };

    var fonts = [
      {
        family: 'Museo Sans',
        cssSrc: './assets/fonts/MuseoSans.css',
      },
    ];

    var styleBase = this.outlineInputStyle ? styleBaseOutline : styleBaseUnderline;
    var style = { ...styleInvalid, ...styleBase };

    this.elements = this.stripeService.instance?.elements({ locale: 'auto', fonts: fonts });

    if (!this.elements) {
      throw 'Stripe failed to initialize';
    }

    this.cardElement = this.elements.create('cardNumber', { style, classes });
    this.cardElement.mount('#payment_card_number');
    this.cardElement.on('change', (event) => {
      this.cardNumberError = event.error?.message;
      this.cardNumberComplete = event.complete;
    });

    this.expiryElement = this.elements.create('cardExpiry', { style, classes });
    this.expiryElement.mount('#payment_card_expiry');
    this.expiryElement.on('change', (event) => {
      this.cardExpiryError = event.error?.message;
      this.cardExpiryComplete = event.complete;
    });

    this.cvcElement = this.elements.create('cardCvc', { style, classes });
    this.cvcElement.mount('#payment_card_cvc');
    this.cvcElement.on('change', (event) => {
      this.cardCvcError = event.error?.message;
      this.cardCvcComplete = event.complete;
    });
  }

  // Data

  didTapDone() {
    const stripeRequest = this.stripeService.instance!.createToken(this.cardElement, {
      address_zip: this.postalCode || undefined,
    });
    this.loadingAlertCtrl.showLoader();
    from(stripeRequest)
      .pipe(
        flatMap((tokenResponse: TokenResult) => {
          if (!tokenResponse.token) {
            throw tokenResponse.error;
          }
          return this.userService.createCreditCard({ token: tokenResponse.token!.id });
        }),
        finalize(() => this.loadingAlertCtrl.dismissLoader())
      )
      .subscribe(
        (card) => {
          this.userService.getCreditCards();
          this.loadingAlertCtrl.showToastConfirmation('Card Saved Successfully');
          this.analytics.trackEvent('Credit Card Added - Client');
          this.dismiss(true);
        },
        (error) => {
          // returns a string if error is from stripe or an object if error is from api.
          this.loadingAlertCtrl.showToastAlert(`Error adding card: ${error.message || error}`);
        }
      );
  }

  get validates() {
    return (
      this.cardNumberComplete &&
      this.cardExpiryComplete &&
      this.cardCvcComplete &&
      this.postalCode?.length === 5
    );
  }

  get hasErrors() {
    return this.cardNumberError || this.cardExpiryError || this.cardCvcError;
  }

  didTapSkip() {
    this.dismiss(true);
  }

  // Actions

  didTapDismiss() {
    this.dismiss();
  }

  didTapBack() {
    this.dismiss();
  }

  private dismiss(data?: any) {
    this.modalService.dismissModal(data);
  }
}
