import * as pluralize from 'pluralize';

import { Component, Input, OnInit } from '@angular/core';

import { AddressService } from 'src/app/services/api/address/address.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { Day } from 'src/app/models/day';
import { FlowPage } from '../../../flow-director';
import { ModalService } from 'src/app/services/modal/modal.service';
import { MultipleOrderParams } from 'src/app/models/order';
import { Service } from 'src/app/models/service';
import { TimeSlot } from 'src/app/models/time-slot';
import { UserAddress } from 'src/app/models/user-address';

export interface DayTimeSelectorPageProps {
  service: Service;
  userAddress: UserAddress;
  vehicleCount?: Nullable<number>;
  duration?: string;
  timeSlots?: TimeSlot[];
  multipleOrderParams?: Nullable<MultipleOrderParams[]>;
}

export interface DayTimeSelectorPageForm {
  slot?: TimeSlot;
}

@Component({
  selector: 'ysh-day-time-selector',
  templateUrl: './day-time-selector.page.html',
  styleUrls: ['./day-time-selector.page.scss'],
})
export class DayTimeSelectorPage implements OnInit, FlowPage {
  @Input() onComplete: (slot: TimeSlot) => void;
  @Input() onDismiss: () => void;
  @Input() preventBackNavigation?: boolean = false;
  @Input() props: DayTimeSelectorPageProps;

  loading = false;
  form: DayTimeSelectorPageForm = {};
  days: Day[] = [];
  selectedDay?: Day;

  constructor(
    private addressService: AddressService,
    private analytics: AnalyticsService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.analytics.trackView('DayTimeSelectorPage');

    if (this.props.timeSlots?.length) {
      this.processSlots(this.props.timeSlots);
    } else {
      this.loadTimeSlots();
    }
  }

  private sortDays(days: Day[]) {
    return days.sort((a, b) => a.nextDate.getTime() - b.nextDate.getTime());
  }

  // Actions

  didTapOnDay(day) {
    this.selectedDay = day;
    this.setDefaultTime();
  }

  didTapOnSlot(slot: TimeSlot) {
    this.form.slot = slot;
  }

  didTapDismiss() {
    this.modalService.dismissModal();
  }

  didTapBack() {
    this.onDismiss?.();
  }

  didTapDone() {
    this.onComplete(this.form.slot!);
  }

  // Data

  private loadTimeSlots() {
    this.loading = true;
    return this.addressService
      .getTimeSlots(
        this.props.userAddress,
        null,
        null,
        this.props.service,
        this.props.multipleOrderParams
      )
      .subscribe((slots) => {
        this.processSlots(slots);
        this.loading = false;
      });
  }

  private processSlots(slots: TimeSlot[]) {
    const days = this.props.userAddress.getDays(24, this.props.service, slots) || [];
    this.days = this.sortDays(days);
    this.setDefaultDay();
    this.setDefaultTime();
  }

  private setDefaultDay() {
    this.selectedDay = this.days.find((day) => day.slots.find((slot) => slot.available));
  }

  private setDefaultTime() {
    this.form.slot = this.selectedDay?.slots.find((slot) => slot.available);
  }

  // Helpers

  isDaySelected(someDay: Day) {
    return this.selectedDay?.dayIndex === someDay.dayIndex;
  }

  isDayServiced(day: Day) {
    return day.slots && day.slots.length > 0;
  }
}
