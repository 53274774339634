import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { AddressService } from 'src/app/services/api/address/address.service';
import { FlowPage } from '../../flow-director';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { MultipleOrderParams } from 'src/app/models/order';
import { Service } from 'src/app/models/service';
import { ServiceGroup } from 'src/app/models/service-group';
import { ServiceSelectorPage } from '../selectors/service-select-modal/service-selector.page';
import { TimeSlot } from 'src/app/models/time-slot';
import { UserAddress } from 'src/app/models/user-address';

export interface AppointmentBuilderPageProps {
  serviceGroup: ServiceGroup;
  service: Service;
  address: UserAddress;
}

export interface AppointmentBuilderPageForm {
  services: Service[];
  slots: TimeSlot[];
  slotCount: number;
  appointmentDuration: number;
}

@Component({
  selector: 'appointment-builder',
  templateUrl: 'appointment-builder.page.html',
  styleUrls: ['appointment-builder.page.scss'],
})
export class AppointmentBuilderPage implements OnInit, FlowPage {
  @Input() onDismiss: () => void;
  @Input() onComplete: (services: Service[], slots: TimeSlot[]) => void;
  @Input() props: AppointmentBuilderPageProps;

  form: AppointmentBuilderPageForm;
  loading = false;
  error: Nullable<string>;

  constructor(private modalService: ModalService, private addressService: AddressService) {}

  ngOnInit() {
    this.form = {
      services: [this.props.service],
      slots: [],
      appointmentDuration: this.props.service.stopDuration,
      slotCount: 0,
    };
    this.getTimeSlots();
  }

  // Data

  private pendingSubscription: Subscription;

  getTimeSlots() {
    const multipleOrderParams: MultipleOrderParams[] = this.form.services.map((item) => {
      return { serviceUid: item.uid };
    });
    if (this.pendingSubscription) {
      this.pendingSubscription.unsubscribe();
    }
    this.loading = true;
    this.error = null;
    this.pendingSubscription = this.addressService
      .getTimeSlots(this.props.address, null, null, this.props.service, multipleOrderParams)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        (slots) => {
          this.form.slots = slots;
          this.form.slotCount = slots.filter((slot) => slot.available).length;
        },
        (error) => (this.error = error)
      );
  }

  // Actions

  didTapBack() {
    this.onDismiss?.();
  }

  didTapNext() {
    this.onComplete(this.form.services, this.form.slots);
  }

  didTapAddVehicle() {
    this.modalService.openPage({
      component: ServiceSelectorPage,
      componentProps: {
        services: this.props.serviceGroup.schedulableWith(this.props.service),
        onComplete: (index) => this.didSelectService(index),
      },
    });
  }

  didSelectService(index: number) {
    const service = this.props.serviceGroup.schedulableWith(this.props.service)[index];
    this.form.services.push(service);
    this.updateDuration();
    this.getTimeSlots();
    this.modalService.dismissModal();
  }

  didTapDeleteService(index: number) {
    this.form.services.splice(index, 1);
    this.getTimeSlots();
    this.updateDuration();
  }

  // Helpers

  private updateDuration() {
    this.form.appointmentDuration = this.form.services.reduce(
      (sum, service) => sum + service.stopDuration,
      0
    );
  }

  get validates() {
    return this.form.services.length && this.form.slotCount && !this.error && !this.loading;
  }
}
