import { Alignment, ServicePresentation } from './service-presentation';
import { BaseEntity, Default, MemoizeGetters, Transform, toEntity } from './base';

import { Faq } from './faq';

@MemoizeGetters
export class ServiceType extends BaseEntity implements ServiceAttributes {
  name: ServiceName;
  title: string;
  gasVehicleOnly: boolean;
  electricVehicleOnly: boolean;

  @Default([])
  photos: ServicePhoto[];

  @Transform(toEntity(Faq))
  faqs: Faq[];

  @Default({})
  @Transform(toEntity(ServicePresentation))
  presentation: ServicePresentation;

  shortDescription: string;

  get logoUrl(): string {
    return this.useProdBucket((this.json.logoUrl as any) || '');
  }

  get imageUrl(): string {
    return this.useProdBucket((this.json.imageUrl as any) || '');
  }

  get thumbnailUrl(): string {
    return this.useProdBucket((this.json.thumbnailUrl as any) || '');
  }

  get isGas(): boolean {
    return this.name === ServiceName.Gas;
  }

  private useProdBucket(url: string) {
    return url.replace('.beta', '').replace('.staging', '');
  }

  get icon() {
    return SERVICE_ICON_IMG[this.name] || DEFAULT_SERVICE_ICON;
  }
}

export interface ServiceAttributes {
  title: string;
  shortDescription: string;
  imageUrl: string;
  thumbnailUrl: string;
}

export enum ServiceName {
  Wash = 'wash',
  RainX = 'rain_x',
  Tires = 'tires',
  OilChange = 'oil_change',
  Detail = 'detail',
  FuelAdditive = 'fuel_additive',
  WiperFluid = 'wiper_fluid',
  WiperBlades = 'wiper_blades',
  Gas = 'gas_refill',
  Polish = 'polish',
  RepairShop = 'repair_shop',
  Checkup = 'checkup',
  Inspection = 'inspection',
  Health = 'health',
  EVCharging = 'ev_charging',
  CertifiedInspection = 'certified_inspection',
}

export type ServiceMap<T> = { [key in ServiceName]?: T };

export const SERVICE_ICON_IMG: ServiceMap<string> = {
  [ServiceName.Gas]:
    'https://s3.us-west-1.amazonaws.com/m.startyoshi.com/assets/images/services/icons/icon-service-gas.svg',
  [ServiceName.Wash]:
    'https://s3.us-west-1.amazonaws.com/m.startyoshi.com/assets/images/services/icons/icon-service-wash.svg',
  [ServiceName.Detail]:
    'https://s3.us-west-1.amazonaws.com/m.startyoshi.com/assets/images/services/icons/icon-service-detail.svg',
  [ServiceName.OilChange]:
    'https://s3.us-west-1.amazonaws.com/m.startyoshi.com/assets/images/services/icons/icon-service-oil.svg',
  [ServiceName.RepairShop]:
    'https://s3.us-west-1.amazonaws.com/m.startyoshi.com/assets/images/services/icons/icon-service-repair.svg',
  [ServiceName.Checkup]:
    'https://s3.us-west-1.amazonaws.com/m.startyoshi.com/assets/images/services/icons/icon-service-checkup.svg',
};

export const DEFAULT_SERVICE_ICON = SERVICE_ICON_IMG[ServiceName.RepairShop];

interface ServicePhoto {
  url: string;
}
