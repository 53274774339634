import { BaseEntity, MemoizeGetters } from './base';

@MemoizeGetters
export class UberUserData extends BaseEntity {
  userHasAccount?: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  uberVehicleUuid?: string;
  vehicleMake?: string;
  vehicleModel?: string;
  vehicleModelUid?: string;
  vehicleYear?: number;
  vehicleColor?: string;
  vehicleColorName?: string;

  get validVehicleInfo() {
    return this.uberVehicleUuid && this.vehicleModelUid && this.vehicleYear;
  }

  get vehicleColorNameNullable() {
    return this.vehicleColorName ? `, ${this.vehicleColorName}` : '';
  }

  get vehicleDescription(): string | null {
    return this.validVehicleInfo ? `${this.vehicleYear} ${this.vehicleMake} ${this.vehicleModel}${this.vehicleColorNameNullable}` : null;
  }
}
