import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ysh-more-details',
  templateUrl: 'ysh-more-details.html',
  styleUrls: ['./ysh-more-details.scss'],
})

export class YshMoreDetailsComponent {
  @Input() details: string;
  @Input() faqs?: string;
  @Output() tapFaqs = new EventEmitter();

  didTapFaqs() {
    this.tapFaqs.emit();
  }
}
