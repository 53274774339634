import { Component, Input } from '@angular/core';

@Component({
  selector: 'ysh-service-select-card',
  templateUrl: 'service-select-card.html',
  styleUrls: ['./service-select-card.scss'],
})
export class ServiceSelectCardComponent {
  @Input() serviceTitle: string;
  @Input() servicePrice: number;
  @Input() serviceDuration: number;
  @Input() serviceDescription?: string;
  @Input() selected: boolean;

  constructor() {}
}
