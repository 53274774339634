import { Component, Input } from '@angular/core';

@Component({
  selector: 'ysh-user-card',
  templateUrl: 'ysh-user-card.html',
  styleUrls: ['./ysh-user-card.scss'],
})
export class YshUserCardComponent {
  @Input() name?: string;
  @Input() email?: string;
  @Input() phone?: string;
  @Input() vehicle?: string;

  constructor() {}
}
