import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';

import { EditVehiclePage } from '../../pages/profile/edit-vehicle/edit-vehicle.page';
import { ModalService } from '../../services/modal/modal.service';
import { PhotoStoryPage } from '../../pages/home/photo-story/photo-story.page';
import { PhotoStoryService } from '../../services/photo-story/photo-story.service';
import { Storage } from '@ionic/storage';
import { Vehicle } from '../../models/vehicle';
import { VehiclesService } from '../../services/api/vehicles/vehicles.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ysh-vehicle-information',
  templateUrl: 'vehicle-information.html',
  styleUrls: ['./vehicle-information.scss'],
})
export class VehicleInformationComponent implements OnDestroy {
  @Input() showEdit = false;
  @Output() onTap: EventEmitter<any> = new EventEmitter();

  private unsubscribe: Subject<void> = new Subject();

  selectedVehicle: Nullable<Vehicle>;
  hasUnseenPhotos: boolean;
  hasPhotoStory: boolean;

  // new Features
  showNewFeatureTooltip = false;
  readonly NEW_FEATURE_KEY = 'mileage-recalls-feature'; // update key for every new feature

  constructor(
    private vehicleService: VehiclesService,
    private storyService: PhotoStoryService,
    private modalService: ModalService,
    private storage: Storage
  ) {
    this.vehicleService.selectedVehicle$.pipe(takeUntil(this.unsubscribe)).subscribe((vehicle) => {
      this.selectedVehicle = vehicle;
    });
    this.subscribeToPhotoStory();
  }

  subscribeToPhotoStory() {
    const photoStorySubscriptions = [
      this.storyService.hasNewPhotos$,
      this.storyService.photoStoryForSelectedVehicle$,
    ];

    combineLatest(photoStorySubscriptions)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response: any[]) => {
        if (!response[1]) {
          // .length on response[1] can run into errors.
          return;
        }
        this.hasUnseenPhotos = response[0] && response[1].length;
        this.hasPhotoStory = response[1].length ? true : false;
      });
  }

  didTapVehicleInfo() {
    this.onTap.emit();
  }
  didTapVehicleImage() {
    this.hasPhotoStory ? this.showPhotoStoryPage() : this.showEditVehiclePage();
  }

  async showPhotoStoryPage() {
    // don't animate
    const photoStoryModal = await this.modalService.openPage({
      component: PhotoStoryPage,
      animated: false,
    });
    photoStoryModal.present();
  }

  async showEditVehiclePage() {
    if (!this.selectedVehicle) {
      return;
    }
    const editVehicleModal = await this.modalService.openPage({
      component: EditVehiclePage,
      componentProps: { modal: true, vehicle: this.selectedVehicle },
    });
    editVehicleModal.present();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
