import { Component, Input, OnInit } from '@angular/core';
import { Order, OrderCancelReason } from 'src/app/models/order';
import { UberEventsService, UberFlowEvent, YoshiAppEvent } from '../services/uber-events.service';
import { flatMap, map, tap } from 'rxjs/operators';

import { ActionSheetController } from '@ionic/angular';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { FlowPage } from '../../flow-director';
import { LoadingAlertService } from 'src/app/services/loading-alert/loading-alert.service';
import { Observable } from 'rxjs';
import { OrderService } from 'src/app/services/api/order/order.service';
import { ServiceGroup } from 'src/app/models/service-group';
import { User } from 'src/app/models/user';

export interface WebflowEndPageProps {
  serviceGroup: ServiceGroup;
  user: User;
}
@Component({
  selector: 'ysh-webflow-end-page',
  templateUrl: './webflow-end.page.html',
  styleUrls: ['./webflow-end.page.scss'],
})
export class WebflowEndPage implements OnInit, FlowPage {
  @Input() props: WebflowEndPageProps;

  loadingBackToUber = false;
  serviceName: string = 'order';
  orders$: Observable<Order[]>;

  onComplete: (order: boolean) => void;
  onDismiss: () => void;

  readonly DEFAULT_SUPPORT_PHONE = '+16502854778';

  constructor(
    public uberService: UberEventsService,
    public orderService: OrderService,
    private analytics: AnalyticsService,
    private loadingAlertCtrl: LoadingAlertService,
    private actionSheetCtrl: ActionSheetController
  ) {}

  // life cycle hooks

  ngOnInit() {
    this.analytics.trackView('WebflowEndPage');
    this.serviceName = this.props.serviceGroup.serviceType.title.toLowerCase();
    const serviceUid = this.props.serviceGroup.serviceType.uid;
    const serviceMatch = (order: Order) => order.service.serviceType.uid === serviceUid;
    this.orders$ = this.orderService.upcomingOrders$.pipe(
      map((orders) => orders.filter(serviceMatch))
    );
  }

  // actions

  async didTapCancel(order: Order) {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Are you sure you want to cancel this order?',
      buttons: [
        {
          text: 'Yes, cancel.',
          handler: () => this.cancelOrder(order),
        },
        {
          text: 'Dismiss',
          role: 'cancel',
        },
      ],
    });
    actionSheet.present();
  }

  didTapOrder() {
    this.onComplete(true);
  }

  didTapBackToUber() {
    this.loadingBackToUber = true;
    this.uberService.sendEvent(YoshiAppEvent.FlowComplete);
    this.uberService.trackAnalyticsEvent(UberFlowEvent.OrderSuccess);
  }

  // data

  private cancelOrder(order: Order) {
    this.loadingAlertCtrl.showLoader();
    this.orderService
      .cancelOrder(order, OrderCancelReason.CustomerRequested)
      .pipe(
        tap(() => {
          this.uberService.sendEvent(YoshiAppEvent.OrderCancel);
        }),
        flatMap(() => {
          return this.orderService.getOrders();
        })
      )
      .subscribe(
        () => {
          this.loadingAlertCtrl.dismissLoader();
          this.loadingAlertCtrl.showToastConfirmation('Order Cancelled!');
          this.orderService.getServiceOrders();
        },
        () => {
          this.loadingAlertCtrl.dismissLoader();
          this.loadingAlertCtrl.showToastAlert('An error occurred.');
        }
      );
    return true;
  }
}
