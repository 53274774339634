import { Component, Input } from "@angular/core";

@Component({
  selector: "ysh-faux-select-dropdown",
  templateUrl: "faux-select-dropdown.html",
  styleUrls: ["./faux-select-dropdown.scss"],
})

export class FauxSelectDropdownComponent {
  @Input() label: string;
  @Input() value: string;

  constructor() {}
}
