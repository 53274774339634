import { Observable, fromEvent, merge, of } from 'rxjs';
import { filter, first, flatMap, map, timeout } from 'rxjs/operators';

import { ApiService } from 'src/app/services/api/api.service';
import { Injectable } from '@angular/core';
import { UberUserData } from 'src/app/models/uber-user-data';

declare var gtag: any;

interface UberEncryptedDataPayload {
  encryptedUberData: string;
  uberVehicleInspectionIdentifier: string;
}

export enum YoshiAppEvent {
  LoadSuccess = 'load_success',
  OrderCancel = 'order_cancel',
  OrderSuccess = 'order_success',
  FlowComplete = 'flow_complete',
}

export const UberFlowEventName = 'uber_flow_event';
export enum UberFlowEvent {
  UberFlowLoaded = 'load_success',
  UberDataReceived = 'data_received',
  UberDataError = 'data_error',
  OrderPlaced = 'order_placed',
  OrderSuccess = 'order_success',
}

@Injectable({
  providedIn: 'root',
})
export class UberEventsService {
  readonly UBER_ORIGIN_WHITELIST = [
    'https://bonjour.uber.com',
    'https://bonjour-dev.uber.com',
    'https://bonjour-staging.uber.com',
  ];
  readonly UBER_EVENT_TIMEOUT = 5000;
  readonly UBER_ENCRYPTED_PAYLOAD_ATTRIBUTE = 'encryptedUberData';

  appIsEmbeddedInUber = false;

  constructor(private api: ApiService) {
    this.appIsEmbeddedInUber = this.getAppIsEmbeddedInUber();
  }

  /**
   * This method encapsulates all logic to retrieve data from Uber's front-end service. It will:
   *   1. Send a message to Uber that we are ready to receive data
   *   2. Listen for a single message with an encrypted data payload
   *   3. Send the payload to the Yoshi backend and receive a decrypted response
   *   4. Time out after an interval if no message is received
   * @returns Observable<UberDataPayload>
   */
  getUberData$(): Observable<UberUserData> {
    const listenForUberMessage$ = fromEvent(window.parent, 'message');
    return merge(listenForUberMessage$, of(this.sendEvent(YoshiAppEvent.LoadSuccess))).pipe(
      filter((event) => !!event),
      first((event: MessageEvent) => this.eventFilter(event)),
      timeout(this.UBER_EVENT_TIMEOUT),
      map((event) => event.data),
      flatMap((data: UberEncryptedDataPayload) => this.decryptData(data))
    );
  }

  sendEvent(event: YoshiAppEvent) {
    setTimeout(() => {
      const EVENT_TYPE = 'yoshi_app_event';
      window.parent?.parent?.postMessage(
        {
          type: EVENT_TYPE,
          event: event,
        },
        '*'
      );
    }, 500);
  }

  trackAnalyticsEvent(event: UberFlowEvent, message?: string) {
    gtag('event', UberFlowEventName, {
      event_name: event,
      message,
    });
  }

  private getAppIsEmbeddedInUber(): boolean {
    const parentIsTop = window.parent.self === window.top;
    const referrer = window.parent?.document?.referrer;
    return !parentIsTop && !!this.UBER_ORIGIN_WHITELIST.find((item) => referrer?.startsWith(item));
  }

  private eventFilter(event: MessageEvent) {
    const originIsAllowed = this.UBER_ORIGIN_WHITELIST.includes(event.origin);
    const hasData = !!event.data?.[this.UBER_ENCRYPTED_PAYLOAD_ATTRIBUTE];
    return originIsAllowed && hasData;
  }

  private decryptData(payload: UberEncryptedDataPayload): Observable<UberUserData> {
    return this.api
      .call({
        url: `/encryption/decrypt_uber_data`,
        method: 'POST',
        body: {
          encryptedData: payload.encryptedUberData,
        },
      })
      .pipe(
        map((response) => {
          const data = response.decrypted_data;
          const json = typeof data === 'string' ? JSON.parse(data) : data;
          const uberUserData = new UberUserData(json);
          uberUserData.uberVehicleUuid = payload.uberVehicleInspectionIdentifier;
          return uberUserData;
        })
      );
  }
}
